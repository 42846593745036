
@media(max-width:7000px) and (min-width:691px){
    .profile-area-portfolio img{
        width: 60%;
        border: 1px solid white;
        padding: 5px;
        text-align: center;
        border-radius: 50%;
    }
    .portfolio-top-area{
        margin-top: -120px;
       /*  border: 1px solid lightgrey; */
    }
    .portfolio-top-back-area{
        height: 200px;
        width: 100%;
        background-color: #353535;
        margin-top: 30px;
    }
    .area-portfolio-top-left h3{
        color: white !important;
        text-align: left;
        font-size:36px;
        margin-bottom: 0px !important;
        font-family: 'Open Sans', sans-serif;
    }
    .area-portfolio-top-left h3{
        color: white !important; 
        text-align: left;
    }
    .area-portfolio-top-left h4{
         color: white !important;
        text-align: left !important;
        font-size:20px;
        margin-top: 20px !important;
        margin-left: 38px;
        font-family: 'Open Sans', sans-serif;
    }
    
.area-portfolio-top-left-bottom{
    margin-top: 100px;
}
.area-portfolio-top-left-bottom p{
    margin-left: 10%;
    color: #353535;
    font-size: 22px;
    margin-bottom: 5px;
}
.area-portfolio-middle-content{
    margin-top: 60px;
}
.area-portfolio-middle-content center h3{
    font-family: 'Open Sans', sans-serif !important;
font-size: 30px;
font-weight: 550;
}
.area-portfolio-middle-content center p{
font-size: 18px !important;
font-weight: 500 !important;
}
}
@media(max-width:691px) and (min-width:0px){
    .profile-area-portfolio img{
        width: 100%;
        border: 1px solid white;
        padding: 5px;
        text-align: center;
        border-radius: 50%;
    }  
    .portfolio-top-area{
        margin-top: -60px;
    } 
    .portfolio-top-back-area{
        height: 120px;
        width: 100%;
        background-color: #353535;
        margin-top: 30px;
    }
    .area-portfolio-top-left h3{
        color: white !important; 
        text-align: left;
        font-family: 'Open Sans', sans-serif;
        font-size: 22px;
        margin-bottom: 0px;
    }
    .area-portfolio-top-left h4{
         color: white !important;
        text-align: left !important;
        font-size: 15px;
        margin-top: 0px;
        font-family: 'Open Sans', sans-serif;
        margin-left: 10px;
    }
    
.area-portfolio-top-left-bottom{
    margin-top: 30px;
}
.area-portfolio-top-left-bottom p{
    margin-left: 0%;
    color: #353535;
    font-size: 16px;
    margin-bottom: 5px;
}
.area-portfolio-middle-content{
    margin-top: 30px;
}
.area-portfolio-middle-content center h3{
font-size: 20px;
font-weight: 550;
}
.area-portfolio-middle-content center p{
font-size: 18px !important;
font-weight: 500 !important;

}
}
@media(max-width:7000px) and (min-width:1100px){
    .area-portfolio-middle-grid-area{
        background-color: #353535;
        border-radius: 50%;
        padding: 20px;
        border: 6px solid #E3E3E3;
        height: 100%; 
        display: flex;
        cursor: pointer;
        justify-content: center;
        align-items: center !important;
    }
    .area-portfolio-middle-grid-area1{
        background-color: #353535;
        border-radius: 50%;
        cursor: pointer;
        height: 100%;
        padding: 30px;
        border: 6px solid #E3E3E3; 
    }
    .area-portfolio-middle-grid-area1 center img{
width: 73%;
margin-bottom: 10px;
    }
    .area-portfolio-middle-grid-area1 h4{
        color: white !important;
        font-size: 19px !important;
        margin-top: 0px !important;
    }
    .area-portfolio-middle-grid-area2{
        background-color: #353535;
        border-radius: 15px;
        cursor: pointer;
        padding: 30px;
        border: 6px solid #E3E3E3; 
        height: 95%;
    }
    .area-portfolio-middle-grid-area2 center img{
width: 73%;
    }
    .area-portfolio-middle-grid-area2 h4{
        color: white !important;
        font-size: 19px !important;
        margin-top: 9px;
    }
    .area-portfolio-middle-grid-area3{
        background-color: #353535;
        border-radius: 15px;
        padding: 30px;
        border: 6px solid #E3E3E3; 
        cursor: pointer;
        height: 95%;
    }
    .area-portfolio-middle-grid-area3 center img{
width: 40%;
    }
    .area-portfolio-middle-grid-area3 h4{
        color: white !important;
        font-size: 19px !important;
        margin-top: 9px;
    }
    .area-portfolio-middle-grid-area4{
        background-color: #353535;
        border-radius: 50%;
        cursor: pointer;
        padding: 30px;
        border: 6px solid #E3E3E3; 
    }
    .area-portfolio-middle-grid-area4 center img{
width: 73%;
    }
    .area-portfolio-middle-grid-area4 h4{
        color: white !important;
        font-size: 19px !important;
    }
}
@media(max-width:1100px) and (min-width:691px){
    .area-portfolio-middle-grid-area{
        background-color: #353535;
        border-radius: 50%;
        padding: 20px;
        border: 6px solid #E3E3E3;
        height: 100%; 
        display: flex;
        cursor: pointer;
        justify-content: center;
        align-items: center !important;
    }
    .area-portfolio-middle-grid-area1{
        background-color: #353535;
        border-radius: 50%;
        cursor: pointer;
        height: 100%;
        padding: 10px 30px 10px 30px;
        border: 6px solid #E3E3E3; 
    }
    .area-portfolio-middle-grid-area1 center img{
width: 73%;
    }
    .area-portfolio-middle-grid-area1 h4{
        color: white !important;
        font-size: 19px !important;
        margin-top: 0px !important;
    }
    .area-portfolio-middle-grid-area2{
        background-color: #353535;
        border-radius: 15px;
        cursor: pointer;
        padding: 30px;
        border: 6px solid #E3E3E3; 
        height: 95%;
    }
    .area-portfolio-middle-grid-area2 center img{
width: 73%;
    }
    .area-portfolio-middle-grid-area2 h4{
        color: white !important;
        font-size: 19px !important;
        margin-top: 9px;
    }
    .area-portfolio-middle-grid-area3{
        background-color: #353535;
        border-radius: 15px;
        padding: 30px;
        border: 6px solid #E3E3E3; 
        cursor: pointer;
        height: 95%;
    }
    .area-portfolio-middle-grid-area3 center img{
width: 40%;
    }
    .area-portfolio-middle-grid-area3 h4{
        color: white !important;
        font-size: 19px !important;
        margin-top: 9px;
    }
    .area-portfolio-middle-grid-area4{
        background-color: #353535;
        border-radius: 50%;
        cursor: pointer;
        padding: 30px;
        border: 6px solid #E3E3E3; 
    }
    .area-portfolio-middle-grid-area4 center img{
width: 73%;
    }
    .area-portfolio-middle-grid-area4 h4{
        color: white !important;
        font-size: 19px !important;
    }
}
@media(max-width:691px) and (min-width:0px){
    .area-portfolio-middle-grid-area{
        background-color: #353535;
        border-radius: 50%;
        border: 6px solid #E3E3E3;
        height: 100%;
        display: flex;
        cursor: pointer;
        justify-content: center;
        align-items: center !important;
    }
    .area-portfolio-middle-grid-area1{
        background-color: #353535;
        border-radius: 50%;
        padding: 2px 10px 2px 10px;
        cursor: pointer;
        height: 100%;
        border: 6px solid #E3E3E3; 
        display: flex;
        justify-content: center;
        align-items: center;
    
    }
    .area-portfolio-middle-grid-area1 center img{
width: 48%;

    }
    .area-portfolio-middle-grid-area1 h4{
        color: white !important;
        font-size: 11px !important;
        margin-top: 0px !important;
        font-family: 'Open Sans', sans-serif;
      
    }
    .my-col-prt{
        padding-left: 5px !important;
        padding-right: 5px !important;
    }
    .area-portfolio-middle-grid-area2{
        background-color: #353535;
        border-radius: 15px;
        padding: 10px 5px 5px 5px;
        cursor: pointer;
        border: 6px solid #E3E3E3;
        height: 95%; 
        display: flex;
        justify-content: center;
        align-items: center !important;
    }
    .area-portfolio-middle-grid-area2 center img{
width: 48%;
    }
    .area-portfolio-middle-grid-area2 h4{
        color: white !important;
        font-size: 13px !important;
        font-family: 'Open Sans', sans-serif;
        margin-top: 8px;
    }
    .area-portfolio-middle-grid-area3{
        background-color: #353535;
        border-radius: 15px;
        padding: 5px;
   cursor: pointer;
        border: 6px solid #E3E3E3;
        height: 95%; 
        display: flex;
        justify-content: center;
        align-items: center !important;
    }
    .area-portfolio-middle-grid-area3 center img{
width: 48%;
    }
    .area-portfolio-middle-grid-area3 h4{
        color: white !important;
        font-size: 13px !important;
        font-family: 'Open Sans', sans-serif;
        margin-top: 8px;
    }
    .area-portfolio-middle-grid-area4{
        background-color: #353535;
        border-radius: 50%;
        padding: 5px;
        cursor: pointer;
        border: 6px solid #E3E3E3; 
    }
    .area-portfolio-middle-grid-area4 center img{
width: 48%;
margin-top: 5px;
    }
    .area-portfolio-middle-grid-area4 h4{
        color: white !important;
        font-size: 13px !important;
        font-family: 'Open Sans', sans-serif;
        margin-bottom: 5px;
    }
}
