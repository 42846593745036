.mainMenuWrapshare{
    @media (min-width:900px) {
        display: flex;
        justify-content: flex-end !important;
        flex-wrap: wrap; 
    }
    @media (max-width:900px) {
        display: flex;
        justify-content: center !important;
        flex-wrap: wrap; 
    }

        li{
      
            display: inline-block;
            position: relative;
            &:first-child{
                padding-left: 0;
            }
            &:last-child{
                padding-right: 0;
            }
            @media (max-width:1200px) {
                padding: 0px 2px !important;
                margin: 2px 0px 2px 0px;
            }
            a{
                display: block;
                font-size: 16px !important;
                font-weight: 400;
                color: #fff;
                letter-spacing: 0;
                padding: 5px 5px !important;
             margin-top: 1px;
             margin-bottom: 3px;
                cursor: pointer;
                i{
                   
                }
                @media (max-width:1200px) {
                    font-size: 16px;
                }
                &.active{
                    color: #c0b596;
                }
            }
            
            &:hover{
                a{
                    display: block;
                    font-size: 16px !important;
                    font-weight: 500;
                    color: #fff;
                    letter-spacing: 0;
                    padding: 5px 5px;
                 margin-top: 1px;
                 margin-bottom: 3px;
                    cursor: pointer;
                    i{
                       
                    }
                    @media (max-width:1200px) {
                        font-size: 16px;
                    }
                    &.active{
                        color: #c0b596;
                    }
                }
                span{
                    color: #c0b596;
                }
            }
            ul.subMenu{
                position: absolute;
             
                @media (min-width:691px) {
                    left: 100%;
                    top: 50px;
                }
                @media (max-width:691px) {
                    left: 0;
                top: 50px;   
                }
                z-index: 999;
                width: 200px;
                padding: 5px 5px;
                background: #fff;
                text-align: left;
                box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.05);
                -webkit-transform: scaleY(0);
                -ms-transform: scaleY(0);
                -o-transform: scaleY(0);
                transform: scaleY(0);
                transform-origin: top;
                -webkit-transition: all 0.3s ease 0s;
                -o-transition: all 0.3s ease 0s;
                transition: all 0.3s ease 0s;
                text-transform: capitalize;
                font-size: 20px !important;
                li{
                    font-size: 20px !important;
                    display: block;
                    padding: 0;
                    border-bottom: 1px solid #f2f2f2;
                    &:last-child{
                        border-bottom: none;

                    }
                    a{
                        @media (min-width:1000px) {
                            font-size: 12px !important;
                            font-weight: 550;
                        }
                          
                       
                        color: #06163a;
                        display: block;
                        padding: 5px 15px;
                        text-transform: capitalize;
                        &:hover,
                        &.active{
                            color: #c0b596;
                        }
                      i{
                          font-size: 16px;
                      }  
                    }
                }
            }
            &:hover{
                ul.subMenu{
                    -webkit-transform: scaleY(1);
                    -ms-transform: scaleY(1);
                    -o-transform: scaleY(1);
                    transform: scaleY(1);
                }
            }
        }
    }